// React
import React, { useRef, useState } from 'react'
import Layout from '../layout'
import Svg from '../images/statys-background-animation-large.svg'
import emailjs from '@emailjs/browser'
import PageTransition from 'gatsby-plugin-page-transitions'
import { Helmet } from "react-helmet"

//Chakra UI
import {
  Container,
  Input,
  FormLabel,
  FormControl,
  Box,
  Stack,
  Heading,
  Text,
  Textarea,
  Image,
  useBreakpointValue,
  Alert, 
  AlertIcon,
} from '@chakra-ui/react'


const Contact = ({ data }) => {

  const form = useRef();

  const [message, setMessage] = useState(" ");
  const [alert, setAlert] = useState(false);

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_hite3si', 'template_lxibecb', form.current, 'iOpvd3Wfq93k8FoSB')
      .then((result) => {
          console.log(result.text);
      }, (error) => {
          console.log(error.text);
      });
    e.target.reset()
    setMessage("Great! You successfully applied to become our contributor. We will review your submission and get back to you as soon as we can.");
    setAlert(true)
  };
  
  const isMobile = useBreakpointValue({ base: true, xl: false })

  return (
    <Layout>
      <Helmet>
          <meta charSet="utf-8" />
          <title>Statys Connect</title>
        </Helmet>
      <PageTransition>
      <Container maxW="480px" py="140px" minHeight="100vh" display="flex" alignItems="center" >
        <Box>
          
          {!isMobile && (<Image src={Svg} style={{position: `absolute`, top:`-72px`, left: `0`, height:`-webkit-fill-available`, width: `30%`}}></Image>)}
        
            <Heading fontSize="48px !important" m="0px !important" color="blue.500" pb="32px" fontWeight="500" >Become a contributor</Heading>
              <Text fontSize="14px" color="gray.600" pb="32px">Do you have insights or research you want to share with us?
We are open to new contributors and would like to learn more about your fields of interest. </Text>
          <Box>
            <form ref={form} onSubmit={sendEmail}>
                <Stack spacing={4}>
                    <FormControl id="name" isRequired>
                        <FormLabel fontFamily="Gotham Medium" color="gray.700">Name</FormLabel>
                        <Input type="text" name="name" placeholder='Full Name' borderColor="gray.200" />
                    </FormControl>
                    <FormControl id="email" isRequired>
                        <FormLabel fontFamily="Gotham Medium" color="gray.700">Email</FormLabel>
                        <Input type="email" name="email" placeholder='Email address' />
                      </FormControl>
                      <FormControl id="interests" isRequired>
                        <FormLabel fontFamily="Gotham Medium" color="gray.700">Field of interest</FormLabel>
                        <Input type="text" name="interests" placeholder='What are your fields of interest?' />
                    </FormControl>
                    <FormControl id="message" isRequired>
                        <FormLabel fontFamily="Gotham Medium" color="gray.700">Message</FormLabel>
                        <Textarea type="email" name="message" placeholder='Your message'/>
                    </FormControl>
                    <Stack>
                    <Stack
                        direction={{ base: 'column', sm: 'row' }}
                        align={'start'}
                        justify={'space-between'}>
                    </Stack>
                      <input type="submit" value="Send" className='submit' />
                      {alert && ( <Alert status='success'> <AlertIcon /> {message}</Alert>)}
                    </Stack>
                </Stack>
              </form>
            </Box>
          </Box>
        </Container>
        </PageTransition>
    </Layout>
  )
}

export default Contact
